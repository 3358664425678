<div class="forgot-pass-word">
  <p class="forgot-pwd"> Forgot Password?</p>
  <form id="forgot-pwd" [formGroup]="forgotPwdForm" (ngSubmit)="forgotPwdRequest($event)">
    <div id="forgotPwdCntnt" class= "forgotPwdCntnt">
      
      <div id="pnlForgotPwdText" class="pwdPnl">
      
      <p id="lblForgotPwdText" class="lblPwd">
      To reset your password, enter the Username or Email Id you use to login:
      </p>
      </div>
      <div id="pnlEmailForgotPwd" class="pnlEmailForgotPwd">
      
      <div id="pnlInnerEmailForgotPwd" class="pwdPnl">
      
      <p id="lblEmailForgotPwd" class="lblPwd">
      Enter Email*
      </p>
      </div>
      <input type="text" formControlName="email" tabindex="301" id="txtEmailForgotPwd" class="txtFieldCls txtFldDashboard">
      </div>
      <div id="forgotPwdBtnPnl" class="forgotPwdBtnPnl">
      
      <div id="pnlSubmitBtn" class="pnlCancelBtn">
      
      <button id="lnkSubmitBtn" class="button lnkCancelBtn" type="submit" tabindex="302" >
      Submit
      </button>
      </div>
      <div id="pnlCancelBtn" class="pnlCancelBtn">
      
      <button id="lnkCancelBtnForgot" class="button lnkCancelBtn" type="submit" tabindex="303" (click)="navigateToHome()">
      Cancel
      </button>
      </div>
      </div>
      </div>
  </form>
</div>
