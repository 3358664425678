<div class="brandContainer">
    <p class="ourBrandLabel"> our Brand</p>
    <div class="brandPanel">
        <span class="brandLeft">
        </span>
        <ul class="brandItr iterator">
            <li *ngFor="let brand of brands">
                <img id ="brandImage" class="center-position" src="{{brand.imageName}}" >
            </li>
        </ul>
        <span class="brandRight">
        </span>
    </div>
</div>