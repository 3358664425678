<div class="contact-us" fxLayout="column">
  <div class="pnlContactHeader">
    <p class="heading">Contact Us</p>
  </div>
  <div class="pnlContactBody">
    <div class="pnlContactLeft col-xs-8">
      <div class="upperPnl">
        <div class="fileCntntBdy">
            <div [innerHtml]='content | safeHtml' class="static-content" *ngIf="content != 'false'"></div>      
        </div>
      </div>
      <div class="lowerPnl">
          <!-- <p class="query-head">Write a Query : </p> -->
          <form fxLayout="column" enctype="multipart/form-data" [formGroup]="contactForm" (ngSubmit)="submitContactQuery()">
            <p class="name">Name</p>
            <input class="text-field" placeholder="Name*" type="text" formControlName="name">
            <p class="subject">Subject</p>
            <select class="text-field" formControlName="queryOption">
              <option *ngFor="let item of subjectList" [value]="item.value">{{item.label}}</option>
            </select>
            <p class="email">Email</p>
            <input class="text-field" placeholder="Email Address*" type="email" formControlName="email">
            <p class="contact">Contact Number</p>
            <input class="text-field" placeholder="Phone No." formControlName="phoneNum" maxlength="10" (keypress)="keyPress($event)"
              type="number" pattern="[0-9]*">
              <p class="message">Message</p>
            <textarea class="text-field text-area" placeholder="Message (max. 500 characters)" style="resize:none"
              formControlName="message"></textarea>
            <div class="attch-file" style="position:relative;">
              <p #fileName style="display:inline-block;color:#000;pointer-events:none;"></p><span #changeMenu class="attchment"
                style="font-weight:bold"> + Add Attachment</span>
              <input type="file" class="file-upload" style="opacity:0;position:absolute; top:0;left:0;width:50%;" (change)="onFileChange($event, fileName, changeMenu)">
            </div>
            <button class="submit" [disabled]="!contactForm.valid">Submit</button>
          </form>
      </div>
    </div>
    <div class="pnlContactRight col-xs-4">
        <div class="contact-upper" >
            <div class="vender-info contact-left">
              <div *ngIf="vendorDetails != undefined" class="contact-mail">
                <p class="sub-heading">Mail Us</p>
                <a [href]="'mailto:'+vendorDetails.ccMail">{{vendorDetails.ccMail}}</a>
              </div>
              <div class="contact-call">
                <p class="sub-heading">Call Us</p>
                <p>{{vendorDetails.ccContactNo}}</p>
              </div>
              <div class="contact-office">
                <p class="sub-heading">Corporate Office</p>
                <p fxShow.gt-xs fxHide.lt-sm>{{vendorDetails.companyDTO.address}},{{vendorDetails.companyDTO.city}}</p>
                <p fxShow.lt-sm fxHide.gt-xs>{{vendorDetails.companyDTO.address}}</p>
                <p fxShow.lt-sm fxHide.gt-xs>{{vendorDetails.companyDTO.city}} - {{vendorDetails.companyDTO.postalCode}}</p>
                <p fxShow.gt-xs fxHide.lt-sm>{{vendorDetails.companyDTO.state}} - {{vendorDetails.companyDTO.postalCode}}</p>
                <p *ngIf="vendorDetails.companyDTO.countryDTO && vendorDetails.companyDTO.countryDTO.countryName">{{vendorDetails.companyDTO.countryDTO.countryName}}</p>
              </div>
            </div>
          </div>
    </div>
  </div>
  <!-- <div class="contact-upper" fxLayout.gt-xs="row" fxLayout.lt-sm="column">
    <div class="vender-info contact-left" fxLayout="column" fxFlex.gt-xs="34" fxFlex.lt-sm="100">
      <div *ngIf="vendorDetails != undefined" class="contact-mail" fxLayout="column">
        <p class="sub-heading">Mail Us</p>
        <a [href]="'mailto:'+vendorDetails.ccMail">{{vendorDetails.ccMail}}</a>
      </div>
      <div class="contact-call" fxLayout="column">
        <p class="sub-heading">Call Us</p>
        <p>{{vendorDetails.ccContactNo}}</p>
      </div>
      <div class="contact-office" fxLayout="column">
        <p class="sub-heading">Corporate Office</p>
        <p fxShow.gt-xs fxHide.lt-sm>{{vendorDetails.companyDTO.address}},{{vendorDetails.companyDTO.city}}</p>
        <p fxShow.lt-sm fxHide.gt-xs>{{vendorDetails.companyDTO.address}}</p>
        <p fxShow.lt-sm fxHide.gt-xs>{{vendorDetails.companyDTO.city}} - {{vendorDetails.companyDTO.postalCode}}</p>
        <p fxShow.gt-xs fxHide.lt-sm>{{vendorDetails.companyDTO.state}} - {{vendorDetails.companyDTO.postalCode}}</p>
        <p *ngIf="vendorDetails.companyDTO.countryDTO && vendorDetails.companyDTO.countryDTO.countryName">{{vendorDetails.companyDTO.countryDTO.countryName}}</p>
      </div>
    </div>
  </div>
  <div class="contact-lower" fxLayout="column">
    <p class="query-head">Write a Query : </p>
    <form fxLayout="column" enctype="multipart/form-data" [formGroup]="contactForm" (ngSubmit)="submitContactQuery()">
      <input class="text-field" placeholder="Name*" type="text" formControlName="name">
      <select class="text-field" formControlName="queryOption">
        <option *ngFor="let item of subjectList" [value]="item.value">{{item.label}}</option>
      </select>
      <input class="text-field" placeholder="Email Address*" type="email" formControlName="email">
      <input class="text-field" placeholder="Phone No." formControlName="phoneNum" maxlength="10" (keypress)="keyPress($event)"
        type="number" pattern="[0-9]*">
      <textarea class="text-field text-area" placeholder="Message (max. 500 characters)" style="resize:none"
        formControlName="message"></textarea>
      <div class="attch-file" style="position:relative;">
        <p #fileName style="display:inline-block;color:#000;pointer-events:none;"></p><span #changeMenu class="attchment"
          style="font-weight:bold"> + Add Attachment</span>
        <input type="file" class="file-upload" style="opacity:0;position:absolute; top:0;left:0;width:50%;" (change)="onFileChange($event, fileName, changeMenu)">
      </div>
      <button class="submit" [disabled]="!contactForm.valid">Submit</button>
    </form>
  </div> -->

</div>