<div id="pnlPopoverCntnGiftMsg" class="pnlPopoverCntnGiftMsg">
  <div class="arrow"></div>
  <form [formGroup]="giftWrapForm" (ngSubmit)="submitGiftInfo()">
    <input formControlName="to" type="text" id="toTxtField" class="toTxtField" placeholder="To">
    <input formControlName="from" type="text" id="fromTxtField" class="fromTxtField" placeholder="From">
    <textarea formControlName="message" class="giftMsgTa" autoresize="false" placeholder="Message"></textarea>
    <div id="saveBtnGiftMsgPnl" class="saveBtnGiftMsgPnl pnlTrackBtn">
      <button class="button trackLnkTrackOrderPopoverBtn lnkTrackBtn" type="submit" tabindex="3">Save</button>
    </div>
  </form>
  <p class="giftAmtMsg">An additional amount of per product will be added to your cart.</p>
  <p id="errorLblForGiftMsg" class="errorLblForGiftMsg" *ngIf="showError">All fields are mandatory.</p>
</div>