<div fxLayout="column" class="main-container" [ngClass]="parentClass">
  <div fxLayout="row" class="collectionPart">
    <div class="filterParent" #filtersPanel [class.fixed]="navIsFixed" *ngIf="!(searchResultsPage || isCollectionUrl) && filterList && filterList.length > 0">
      <div class="filterDiv">
        <div class="pnlSubCategoryHeading">
          <p>{{pageTitle}}</p>
        </div>
        <!-- <div class="filter-mob" *ngIf="forTemplate">
          <p>Filter By</p>
          <span class="fa fa-lg fa-filter filter-span" (click)="showFilters(filtersPanel)"></span>
        </div> -->
        <!-- <perfect-scrollbar> -->
        <div *ngIf="catMap != undefined" class="pnlCategoryOuter">
          <div class="catName">
            <a *ngFor="let cat of catMap" (click)="navigateToCategoryUrl(cat.catUrl)" [ngClass]="{'selected':cat.catName == pageTitle}">{{cat.catName}}</a>
          </div>
        </div>
        <!-- </perfect-scrollbar> -->
        <div>
          <app-filters (onSelectingFilter)="updateCollection($event)" (applyFilterClose)="hideFiltersPanel()"
            [filter-list]="filterList"></app-filters>
        </div>
      </div>
    </div>
    <div fxLayout="column" class="collectionParent">
      <div class="selectedFiltersPanel" *ngIf="FilterModel != undefined && FilterModel.length > 0 && forTemplate">
        <h4 class="showResultsHeading">Showing Results in : </h4>
        <div class="filterTagsPanel">
          <ul class="filterTypeIterator">
            <li *ngFor="let filter of FilterModel; let i=index;">
              <div class="selFilterTypePanel" *ngIf=" filter.filterModelList != undefined && filter.filterModelList.length > 0">
                <p class="filterTypeLabel">{{filter.filterName}} : </p>
                <ul class="filterNameIterator">
                  <ls-tag-container (tag-removed)="remove($event)" [tags]="filter?.filterModelList"></ls-tag-container>
                </ul>
              </div>
            </li>
          </ul>
          <div class="clearAllPanel" (click)="clearAllFilters()" *ngIf="FilterModel.length > 0">
            <span class="crossIcon fa fa-close"></span>
            <p class="clearAllLabel">Clear All Filters</p>
          </div>
        </div>
      </div>
      <div fxLayout="row" class="gridListView" fxShow.gt-xs fxHide.lt-sm *ngIf="!errorPage && forTemplate">
        <div class="fa fa-align-justify list" (click)="listView()"></div>
        <div class="fa fa-th grid" (click)="gridView()"></div>
      </div>
      <div class="title" *ngIf="!errorPage">
        <h2>{{pageTitle}}</h2>
        <span *ngIf="!searchResultsPage" class="fa fa-lg fa-filter filter-span filter-icon" (click)="showFilters()"></span>
      </div>
      <div>
        <div *ngFor="let product of products" class="tile-container" [ngClass]="{'list-view':list_View, 'max-width': (searchResultsPage || isCollectionUrl)}">
          <app-product-tile [tile-data]="product" [view]="listViewStr" (addToCartWishlist)="addToWishlist($event)"></app-product-tile>
        </div>
      </div>
      <div class="pnlShowMore" *ngIf="!errorPage" [ngClass]="{'show':hasMore}" (click)="showMore()">
        <a class="lnkShowMore">Show More Products</a>
      </div>
      <div class="pnlNoResultOuter" *ngIf="errorPage">
        <p id="lblNoResult" *ngIf="cartSettings?.allowGetForMe == 0">No Results Found !!</p>
        <div class="pnlNoResultInner" *ngIf="cartSettings?.allowGetForMe == 1">
          <p class="noResultLbl1">Couldn't find what you were looking for?</p>
          <p class="noResultLbl2">Just send us detail of the product and we will update you.</p>
          <div class="getForMe" (click)="getForMe()">
            <a>Get For Me</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="seoContent" *ngIf="content != undefined && content != 'false' && showSeo">
    <div [innerHtml]='content | safeHtml' class="static-content"></div>
  </div>
  <div *ngIf="showError" class="no-results">
    <p *ngIf="cartSettings?.allowGetForMe == 0">No results found matching your search criteria. Please rephrase your
      keywords and try again.</p>
    <div class="pnlNoResultInner" *ngIf="cartSettings?.allowGetForMe == 1">
      <p class="noResultLbl1">Couldn't find what you were looking for?</p>
      <p class="noResultLbl2">Just send us detail of the product and we will update you.</p>
      <div class="getForMe" (click)="getForMe()">
        <a>Get For Me</a>
      </div>
    </div>
  </div>
  <div fxLayoutAlign="center center" class="errorPage" *ngIf="parentClass == 'showErrorPage'">
    <img [src]="errorPageUrl" style="max-width:100%; max-height:100%;">
  </div>
</div>