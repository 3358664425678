import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CartCountService } from '../../../services/cart-count.service';
import { CartModel } from '../../../common/models/cartModel';
import { CartService } from '../../../services/cart.service';
import { Constants } from '../../../common/constants/lsnetx.constants';

@Component({
  selector: 'app-gift-wrap',
  templateUrl: './gift-wrap.component.html',
  styleUrls: ['./gift-wrap.component.scss']
})
export class GiftWrapComponent implements OnInit {
  giftWrapForm: FormGroup;
  showError: boolean = false;
  @Input('giftIndex') giftIndex: number;
  @Input('cart') cart: CartModel;
  @Input('edit') edit: number;
  @Output('giftWrapDetail') giftWrapEvent = new EventEmitter<any>();

  constructor(
    public formBuilder: FormBuilder,
    public cartCountService: CartCountService,
    public cartService: CartService
  ) { 
    this.giftWrapForm = this.formBuilder.group({
      to: ["", [Validators.required]],
      from: ["", [Validators.required]],
      message: ["", [Validators.required]]
    })
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.edit) {
      if (this.cart.cartProductModels.length > 0) {
        for (let index = 0; index < this.cart.cartProductModels.length; index++) {
          var element = this.cart.cartProductModels[index];
          if (this.giftIndex == index && element.giftWrapping ==Constants.GIFT_WRAPPING_TYPE.ENABLE && this.edit) {
            element.giftWrapping = Constants.GIFT_WRAPPING_TYPE.ENABLE;
            this.giftWrapForm.patchValue({from:element.giftFrom});
            this.giftWrapForm.patchValue({to:element.giftTo});
            this.giftWrapForm.patchValue({message:element.giftMsg});
          }
        }
      }
    }
  }

  submitGiftInfo() {
    if (this.giftWrapForm.valid) {
      this.showError = false;
      let to = this.giftWrapForm.value.to;
      let from = this.giftWrapForm.value.from;
      let message = this.giftWrapForm.value.message;
      let giftIndex = this.giftIndex;
      let cart: CartModel = new CartModel();
      cart = this.cart;
      if (cart.cartProductModels.length > 0) {
        for (let index = 0; index < cart.cartProductModels.length; index++) {
          var element = cart.cartProductModels[index];
          if (giftIndex == index && (element.giftWrapping == Constants.GIFT_WRAPPING_TYPE.DISABLE||this.edit)) {
            element.giftWrapping = Constants.GIFT_WRAPPING_TYPE.ENABLE;
            element.giftFrom = from;
            element.giftTo = to;
            element.giftMsg = message;
          }
          else if (giftIndex == index && element.giftWrapping == Constants.GIFT_WRAPPING_TYPE.ENABLE) {
            element.giftWrapping = 0;
          }
        }
        this.giftWrapEvent.emit(cart.cartProductModels[this.giftIndex]);
      }
    } else {
      this.showError = true;
    }
  }
}
