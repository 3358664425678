import { Component, OnInit, ViewChildren, QueryList, ElementRef, Inject, PLATFORM_ID, Input } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CategoryService } from '../../../services/category.service';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { LsMdDialog } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { HomePageService } from '../../../services/home-page.service';
import { CurrencyModel } from '../../../common/models/currency-model';
import { CategoryModel } from '../../../common/models/category-model';
import { UserModel } from '../../../common/models/user-model';
import { LoginComponent } from '../../components/login/login.component';
import { LoginService } from '../../../services/login.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { UserService } from '../../../services/user.service';
import { CustomerSurveyService } from '../../../services/customerSurvey.service'
import { CustomerSurveyComponent } from '../../commonComponent/customer-survey/customer-survey.component'
import { AnswerModel } from '../../../common/models/answerModel'
import { CartCountService } from '../../../services/cart-count.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  // categoryList: Array<CategoryModel>;
  headerDialog: LsMdDialog<CategoryComponent>
  // currencyList: Array<CurrencyModel>
  loggedIn: boolean
  guestLoggedIn: boolean = false
  userModel: UserModel
  userName: string
  showCurrency: boolean
  dialog: LsMdDialog<CategoryComponent>;
  selectedCurrencyIndex: number;
  formId: number

  @ViewChildren("frstLevel") firstLevelEle: QueryList<ElementRef>;
  @ViewChildren("secLevel") secondLevelEle: QueryList<ElementRef>;
  @Input('currencyMap') currencyList: Array<CurrencyModel>
  @Input('categoryList') categoryList: Array<CategoryModel>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private categoryService: CategoryService,
    private dialogService: LsDialogService<LoginComponent>,
    private homePageService: HomePageService,
    private loginService: LoginService,
    private userService: UserService,
    private customerSurveyService: CustomerSurveyService,
    private cartCountService: CartCountService,
    public customerSurveyDialogService: LsDialogService<CustomerSurveyComponent>
  ) { }

  ngOnInit() {
    // this.getCategories();
    // this.fetchCurrencies();
    if (isPlatformBrowser(this.platformId)) {
      // if (localStorage.getItem('currentUser') !== null) {
      //   this.loggedIn = true;
      //   this.userModel = JSON.parse(localStorage.getItem('currentUser'));
      //   this.userName = this.userModel.userName != null ? this.userModel.userName : this.userModel.fName;
      // }

      if (sessionStorage.getItem('currencyId') != null || sessionStorage.getItem('currencyId') != undefined) {
        this.selectedCurrencyIndex = parseInt(sessionStorage.getItem('currencyId')) - 1;
      } else {
        this.selectedCurrencyIndex = 0;
      }
    }

    this.userService.userStateModel.subscribe((userModel) => {
      this.userModel = userModel
      this.userName = this.userModel.userName != null ? this.userModel.userName : this.userModel.fName
      if (Object.keys(userModel).length != 0) {
        this.loggedIn = true
        if (userModel.userType == Constants.UserType.guest) {
          this.guestLoggedIn = true
        }
      } else {
        this.loggedIn = false
      }
    })

  }

  // getCategories() {
  //   this.categoryService.getCategories(Constants.GET_CATEGORY_MODE.ALL, resp => {
  //     this.categoryList = resp.data
  //     Constants.CATEGORY_MAP = resp.data;
  //   })
  // }


  showSubCat(index, $event) {
    let ele = this.firstLevelEle.toArray()[index];
    if ($event.target.className.includes('mob-arrow-down')) {
      $event.target.className = "mob-arrow-up";
    } else {
      $event.target.className = "mob-arrow-down";
    }
    if (ele.nativeElement.className.includes("show-sub-cat")) {
      ele.nativeElement.className = "itrCat";
    } else {
      ele.nativeElement.className = "itrCat show-sub-cat"
    }
  }

  showSecondCat(parentIdx, idx, event) {
    let absIndex = 0;
    for (let i = 0; i < parentIdx; ++i) {
      absIndex += this.categoryList[i].categoryModels.length;
    }

    let secLevelIndex = absIndex + idx;

    for (let j = idx; j >= 0; --j) {
      if (this.categoryList[parentIdx].categoryModels[j].categoryModels == undefined || this.categoryList[parentIdx].categoryModels[j].categoryModels.length == 0) {
        --secLevelIndex;
      }
    }

    let ele = this.secondLevelEle.toArray()[secLevelIndex];
    if (event.target.className.includes('arrow-upleft')) {
      event.target.className = "arrow-upRight";
    } else {
      event.target.className = "arrow-upleft";
    }
    if (ele.nativeElement.className.includes("show-sec-cat")) {
      ele.nativeElement.className = "itrCat secCat";
    } else {
      ele.nativeElement.className = "itrCat secCat show-sec-cat";
    }
  }


  showLoginPopup() {
    Constants.MENU_BAR_NAV.toggle();
    this.dialogService.open(LoginComponent, { panelClass: 'loginPopUp' }, null).subscribe(resp => {

    })
  }

  fetchCurrencies() {
    this.homePageService.getCurrencyDetails(resp => {
      if (resp.data.length > 1) {
        this.showCurrency = true;
        this.currencyList = resp.data;
        let currMap: Array<{ 'id': any, 'code': any }> = []
        this.currencyList.forEach(ele => {
          currMap.push({
            id: ele.currencyId,
            code: ele.currencyCode
          })
        })
        if (isPlatformBrowser(this.platformId)) {
          sessionStorage.setItem('currencyMap', JSON.stringify(currMap));
        }
      }
    });
  }

  // logOut() {
  //   this.loginService.logOut(resp => {
  //     Constants.MENU_BAR_NAV.toggle();
  //     if (resp != null && resp != undefined) {
  //       this.dialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(response => {
  //         if (isPlatformBrowser(this.platformId)) {
  //           window.location.href = "";
  //         }
  //       })
  //     }
  //   });
  // }


  logOutFunc() {
    var maintainLocalCart = false;
    this.loginService.logOut(maintainLocalCart, resp => {
      if (resp != null && !resp.error) {
        this.dialogService.open(OkayMessageComponent, {}, "You have successfully Logged-out!").subscribe(response => {
          if (isPlatformBrowser(this.platformId)) {
            window.location.href = "";
          }
        })
      }
    });
  }

  logOut() {
    // this.openSurvey()
    this.logOutFunc();
  }

  openSurvey() {
    let data: Array<{ formId: number }> = []
    this.customerSurveyService.getSurveyForm(resp => {
      if (!resp.error) {
        if (resp.data != undefined && resp.data != null && resp.data.formId != undefined && resp.data.formId != null)
          this.formId = resp.data.formId
        data.push({
          formId: this.formId
        })
        this.customerSurveyDialogService.open(CustomerSurveyComponent, {}, data).subscribe((response) => {
          if (response == 'yes') {
            let answerModel = new AnswerModel()
            answerModel.formId = this.formId
            let isSurveyFormFilled = false
            this.cartCountService.surveyFormFillObservable.subscribe((flag) => {
              isSurveyFormFilled = flag
            })
            if (!isSurveyFormFilled) {
              this.customerSurveyService.submitQuestionsForSurvey(answerModel, (resp) => {
                if (!resp.error)
                  this.logOutFunc()
              })
            } else {
              this.logOutFunc()
            }
          }
        });
      } else {
        this.logOutFunc()
      }
    });
  }


  updateCurrency(selectedCurrencyIndex: number, currencyOption) {
    this.selectedCurrencyIndex = selectedCurrencyIndex;
    let currencyId = this.currencyList[this.selectedCurrencyIndex].currencyId;
    currencyOption.className = "currency-option";
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.setItem('currencyId', currencyId + "");
      window.location.reload();
    }
  }

  showCategories(event) {
    if (event.target.className.includes('cat-lbl')) {
      if (event.target.className.includes('show-cat')) {
        event.target.className = "hide-cat cat-lbl";
      } else {
        event.target.className = "show-cat cat-lbl";
      }
    } else if (event.target.className.includes('shop-label')) {
      if (event.target.parentElement.className.includes('show-cat')) {
        event.target.parentElement.className = "hide-cat cat-lbl";
      } else {
        event.target.parentElement.className = "show-cat cat-lbl";

      }
    }
  }

  showCurrencyList(event) {
    if (event.target.className.includes('curr-lbl')) {
      if (event.target.className.includes('show-cat')) {
        event.target.className = "hide-cat curr-lbl";
      } else {
        event.target.className = "show-cat curr-lbl";
      }
    } else if (event.target.className.includes('curr-choose')) {
      if (event.target.parentElement.className.includes('show-cat')) {
        event.target.parentElement.className = "hide-cat curr-lbl";
      } else {
        event.target.parentElement.className = "show-cat curr-lbl";

      }
    }
  }

  showAccInfo(event) {
    if (event.target.className.includes('my-acc')) {
      if (event.target.className.includes('show-cat')) {
        event.target.className = "hide-cat my-acc";
      } else {
        event.target.className = "show-cat my-acc";
      }
    } else if (event.target.className.includes('acnt-label')) {
      if (event.target.parentElement.className.includes('show-cat')) {
        event.target.parentElement.className = "hide-cat my-acc";
      } else {
        event.target.parentElement.className = "show-cat my-acc";

      }
    }
  }

  closeSideNav() {
    Constants.MENU_BAR_NAV.toggle();
  }

}
