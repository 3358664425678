<footer *ngIf="!checkOutVisibility">
  <div class="brand" *ngIf="brands && brands.length > 0">
    <app-brands [brands]="brands"></app-brands>
  </div>
  <div class="footerPnl">
    <div class="pnlFooter">
      <div class="footerContact">
        <div class="pnlContactUs">
          <div class="pnlContactHeader">
            <p class="footerContactLabel">
              Who We Are
            </p>
          </div>
          <div class="pnlContactBody">
            <div class="pnlContactLeft">
              <div class="paraContactNote">{{vendorDetails?.storeDesc}}</div>
              <div class="pnlContactMail">
                <span class="fa fa-envelope"></span>
                <a [href]="'mailto:'+vendorDetails.ccMail">{{vendorDetails.ccMail}}</a>
              </div>
              <div class="pnlContactCall">
                <span class="fa fa-print"></span>
                <p class="heading">{{vendorDetails.ccContactNo}}</p>
              </div>
              <div class="pnlContactOffice">
                <span class="fa fa-location-arrow"></span>
                <p fxShow.gt-xs fxHide.lt-sm>{{vendorDetails.companyDTO.address}},{{vendorDetails.companyDTO.city}}</p>
                <!-- <p fxShow.lt-sm fxHide.gt-xs>{{vendorDetails.companyDTO.address}}</p>
                <p fxShow.lt-sm fxHide.gt-xs>{{vendorDetails.companyDTO.city}} - {{vendorDetails.companyDTO.postalCode}}</p> -->
                <p fxShow.gt-xs fxHide.lt-sm>{{vendorDetails.companyDTO.state}} - {{vendorDetails.companyDTO.postalCode}}</p>
                <p *ngIf="vendorDetails.companyDTO.countryDTO && vendorDetails.companyDTO.countryDTO.countryName">{{vendorDetails.companyDTO.countryDTO.countryName}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="infoCont">
        <p class="infoFootLabel">INFORMATION</p>
        <div class="pnlAccount">
          <div class="pnlAccountLinks">
            <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/profile' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Profile</a>
            <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/orders' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Order
              History</a>
            <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/transactions' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Transaction
              Logs</a>
            <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/change-password' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Change
              Password</a>
            <a class="lnkStores" [routerLink]="loggedInUser ? 'my-dashboard/wishlist' : 'login'" [queryParams]="!loggedInUser ? queryParams : ''">Wishlist</a>
          </div>
        </div>
        <div class="quickConPanel" *ngIf="quickLinks != undefined">
          <ul id="quickLinkItr">
            <li *ngFor="let quickLink of quickLinks">
              <a *ngIf="quickLink.pageURL.includes('http')" [href]="httpUrl" class="lnkStores" target="_blank">{{quickLink.linkName}}</a>
              <a *ngIf="!quickLink.pageURL.includes('http')" [href]="quickLink.pageURL" class="lnkStores">{{quickLink.linkName}}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="twitterWid">
        <div class="twitterWidget">
          <a class="twitter-timeline">Tweets By @Twitter</a>
        </div>
        <div class="pnlNewsLetter" *ngIf="showNewsletter">
          <p class="lblSubscribe">
            Subscribe to Our Newsletter
          </p>
          <input type="text" class="tfSubscriptionEmail" [placeholder]="'Subscribe to our newsletter'" (keydown)="subscribeKeyup($event, error, inputVal)"
            #inputVal>
          <span class="sendSpan" (click)="subscribeNewsletter(error, inputVal)">Send</span>
          <p #error class="visibility-hide errorLbl">&nbsp;</p>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright" fxLayout="row">
    <div class="defaultWidth">
      <p class="copyright-txt" fxFlex="70">
        {{copyRTxt}}
      </p>
      <div class="power-by" fxLayout="row" fxLayoutAlign.gt-xs="end end" fxLayoutAlign.lt-sm="center end" fxFlex="30">
        <p fxShow.gt-xs fxHide.lt-sm class="power-txt">Powered By</p>
        <a href="http://www.lsnetx.com" target="_blank" class="copy-right-img"><img src="assets/logo.png" title="LSNetX"
            alt="LSNetX"></a>
      </div>
    </div>
  </div>
  <div>
  </div>
</footer>