<div fxLayout="column" class="popUpMsgInfoDiv" *ngIf="!showNewsLetter">
  <div class="body">
    <p>{{msg}}</p>
  </div>
  <div class="footer">
    <button class="okBtn" (click)="closeDialog()">OK</button>
  </div>
</div>
<div class="pnlNewsLetter" *ngIf="showNewsLetter">
  <div class="pnlCancel">
    <span class="fa fa-times-circle-o" (click)="closeDialog()"></span>
  </div>
  <p class="lblSubscribe">Subscribe to Our Newsletter</p>
  <input type="email" [placeholder]="'Subscribe to our Newsletter'" (keydown)="subscribeKeyup($event, error, input)" #input>
  <p #error class="visibility-hide errorLbl">&nbsp;</p>
  <span class="sendSpan" (click)="subscribeNewsletter(error, input)">Send</span>
</div>