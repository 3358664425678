<div *ngIf="emptyCart" id="cartEmptyDiv" class="cartEmptyDiv">
    <div id="cartEmptyDivInner" class="cartEmptyDivInner">
        <p id="lblEmptyBag" class="lblEmptyBag">Your Bag is Empty!!</p>
        <a [routerLink]="''" id="lnkGoShopping" class="lnkGoShopping">
            <img [src]="imgGoShopPath">
        </a>
    </div>
</div>
<div id="cartWrapper" *ngIf="!emptyCart">
    <div class="pnlShopBag">
        <span class="fa fa-lg fa-shopping-cart"></span>
        <p class="lblCartNum">{{cartCount}}</p>
    </div>
    <div id="cartTblTitlDiv" class="cartTblTitlDiv">
        <p class="col-xs-4 cartTblTitlLbl">ITEM</p>
        <p class="col-xs-1 cartTblTitlLbl">QTY</p>
        <p class="col-xs-2 cartTblTitlLbl">PRICE</p>
        <p class="col-xs-3 cartTblTitlLbl">DELIVERY DETAILS</p>
        <p class="col-xs-2 cartTblTitlLbl">SUBTOTAL</p>
    </div>
    <div class="cartItemItrDiv">
        <ul class="cartItemItr">
            <li class="floatedLeft" *ngFor="let cartProduct of cartProducts; let cartProdIdx = index;">
                <div id="cartItmDetlsDiv">
                    <div id="itemDtls" class="col-xs-4 itemDtls noPadding">
                        <div id="cartItmDetlsDiv_1" class="col-xs-4 cartItmDetlsDiv noPadding">
                            <a id="lnkItem" [href]="'details/'+cartProduct.productModel.productURLText+'?t='+cartProduct.productModel.titleId">
                                <img class="img-Item img-responsive center-position" [src]="(cartProduct?.productModel?.imageUrl && cartProduct?.productModel?.images && cartProduct?.productModel?.images[0])? 
                                cartProduct?.productModel?.imageUrl +'m/'+ cartProduct?.productModel?.images[0]:defaultPath"
                                    alt="" (error)="imgErrorHandler($event)">
                            </a>
                        </div>
                        <div id="cartItmDetlsDiv_2" class="col-xs-8 cartItmDetlsDiv noPadding ">
                            <a class="cartItemTtl " title="cartProduct.productModel.productName">{{cartProduct.productModel.productName}}</a>
                            <div class="pnlItmDel">
                                <span class="delSpan fa fa-trash" (click)='removeProduct(cartProduct, cartProdIdx)'></span>
                            </div>
                            <!-- <div class="giftWrapChck">
                                <label class="giftWrapChck-label">Gift Wrap</label>
                                <input (change)="giftWrapApplied($event,cartProdIdx)" [(ngModel)]="checkedGiftWrap[cartProdIdx]"
                                    class="giftWrapChck-selector" type="checkbox">
                                <div class="gift-wrap" *ngIf="showGiftWrap[cartProdIdx]">
                                    <div class="close" (click)="initializeGiftWrapCheckedArray()">
                                        <span class="fa fa-close"></span>
                                    </div>
                                    <app-gift-wrap (giftWrapDetail)="giftDetails($event)" [giftIndex]="cartProdIdx"
                                        [cart]="cart" [edit]="edit"></app-gift-wrap>
                                </div>
                            </div> -->
                            <!-- <div class="edit" [ngClass]="{'hide':!checkedGiftWrap[cartProdIdx]}" (click)="editGiftWrap(cartProdIdx)">
                                    <p>Edit</p>
                            </div> -->
                        </div>
                    </div>
                    <div id="itemDtls" class="col-xs-1 itemDtls noPadding">
                        <div id="pnlForQuantity">
                            <div id="pnlSpinner">
                                <div id="outerDiv">
                                    <div id="firstDiv">
                                        <input type="text" id="textField" value="{{cartProduct.numCopies}}" disabled>
                                    </div>
                                    <div id="secondDiv">
                                        <div id="productQuantitySpinnerUp" (click)="increaseProQuantity(cartProduct, cartProdIdx)"></div>
                                        <div id="productQuantitySpinnerDown" (click)="decreaseProQuantity(cartProduct, cartProdIdx)"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="itemDtls" class="col-xs-2 itemDtls noPadding">
                        <div id="cartItmDetlsDivInner">
                            <div id="pnlDiscntdPrice">
                                <span id="lblDiscntdPriceRupee" class="fa fa-inr col-xs-1"></span>
                                <p id="lblDiscntdPrice" class="col-xs-10">{{cartProduct.productModel.salePrice
                                    |number:priceRoundOffInteger}}</p>
                            </div>
                            <div id="pnlPriceCart" *ngIf="cartProduct.productModel.mrp > cartProduct.productModel.salePrice">
                                <!-- *ngIf="cartProduct.productModel.mrp > cartProduct.productModel.salePrice" -->
                                <span id="lblDiscntdPriceRupee" class="fa fa-inr col-xs-1"></span>
                                <p id="lblPriceCart" class="col-xs-10">{{cartProduct.productModel.mrp |
                                    number:priceRoundOffInteger}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-3 itemDtls noPadding">
                        <!-- <p class="lblShippingDays">Estimated Shipping in :</p>
                        <p class="valueShippingDays" *ngIf="wishlistItem.productModel.shippingDays > 0">
                            {{wishlistItem.productModel.shippingDays}}-{{wishlistItem.productModel.shippingDays +
                            Constants.PRODUCT_SHIPPING_DAYS_ADDITION}}&nbsp;days
                        </p>
                        <p class="valueShippingDays" *ngIf="wishlistItem.productModel.shippingDays == 0">
                            {{shippingDays}}-{{maxShippingDays}}&nbsp;days
                        </p> -->
                    </div>
                    <div id="itemDtls" class="col-xs-2 itemDtls noPadding">
                        <p id="lblSubTtl">{{cartProduct.productModel.salePrice*cartProduct.numCopies |
                            number:priceRoundOffInteger}}</p>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="cartGrandTotalDiv">
        <div id="orderSmryPnl">
            <p id="orderLbl">Order Summary</p>
            <span id="incTag">*includes GST, Handling and Shipping Charges</span>
        </div>
        <div class="grandTotal">
            <span id="text"> Amount Payable:</span>
            <span id="value">{{grandTotal | currency:currType:'symbol': priceRoundOffInteger}}</span>
        </div>
    </div>
    <div class="cartFooterDiv">
        <a class="shopMore" [routerLink]="''" (click)="onClose()">Shop More</a>
        <a [class.gayab]="!enquireVisibility" (click)="enquiryClick()" [routerLink]="'enquiry'" class="enquireNow">Enquire
            Now</a>
        <a (click)="proceedToCheckout()" class="checkout">Proceed</a>
    </div>
</div>