<div *ngFor="let collection of collectionArray; let i=index;" [ngClass]="{'hide': isDetailPage && collection?.detailPageVisibility == 0 || (collection?.collectionType == CONSTANTS?.DEFAULT_COLLECTION_TYPE?.relatedProducts && collection?.productModels && collection?.productModels.length == 1 && collection?.productModels[0].titleId == newProd?.titleId)}">
  <div class="catHeadingWrapper" [ngClass]="{'boxShadow': isDetailPage}">
    <p class="catHeading">{{collection?.collectionTitleName}}</p>
  </div>
  <div class="viewAllPnl" *ngIf="collection?.collectionType != CONSTANTS?.DEFAULT_COLLECTION_TYPE?.relatedProducts">
    <a class="gridView" href="" routerLink="/collections/{{collection?.collectionURL}}" >View All</a>
  </div>
  <div class="mainDiv defaultWidth">
    <div class="gridArrow listLeftArrow" [ngClass]="{'disableArrow': leftValue[i] >= 0 || collection?.productModels?.length <= showCount}"
      (click)="moveRight(container, i)">
    </div>
    <div class="productsWrapper">
      <div [style.left.px]="leftValue[i]" class="productList">
        <!-- hide same product(current product in detailPage) from related products collections -->
        <div class="products" *ngFor="let productData of collection?.productModels;" [ngClass]="{'hide': collection?.collectionType == CONSTANTS?.DEFAULT_COLLECTION_TYPE?.relatedProducts && productData?.titleId == newProd?.titleId }" #container>
          <app-product-tile [tile-data]="productData" style="width: 100%"></app-product-tile>
        </div>
      </div>
    </div>
    <div [ngClass]="{'disableArrow': leftValue[i] <= maxLeftValue[i] || collection?.productModels?.length <= showCount } "
      class="gridArrow listRightArrow" (click)="moveLeft(container, i)">
    </div>
  </div>
</div>