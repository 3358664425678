<div *ngIf="prodData != undefined" class="tileWrapper" [class.showHoverDiv]="showVariants" [class.on-product-tile]="onProductTile"
(mouseleave)="mouseLeave()" (mouseenter)="mouseEnter()">
<div class="imageWrapper" >
  <!-- <div class="addWishPanel fa fa-heart-o" (click)="addToWishlist()"></div> -->
  <a [routerLink]="'/'+target" [queryParams]="queryParams" style="height: calc(100% - 20px);">
    <img [src]="(prodData?.imageUrl && prodData?.images && prodData?.images[0])?prodData.imageUrl+'m/'+prodData?.images[0]:defaultPath "
      class="img-responsive" alt="" (error)="imgErrorHandler($event)"> 
  </a>
</div>
<div class="detailDiv">
  <p id="productName"> {{productName}}</p>
  <div class="priceDiv">
    <p id="productMrp" *ngIf="prodData?.mrp > prodData?.salePrice && showSaving == 1">
      {{prodData?.mrp | currency:currType:'symbol': priceRoundOffInteger}}
    </p>
    <p id="productSalePrice">{{prodData?.salePrice | currency:currType:'symbol': priceRoundOffInteger}}</p>
    <p class="discount" *ngIf="data?.discountPercent && data?.discountPercent!=0">{{data?.discountPercent}}% &nbsp;off</p>
  </div>
  <!-- <div class="ratingDiv" *ngIf="ratingValue > 0">
    <ls-rating [editable]=false name="ratingVal" [(ngModel)]="ratingValue" ></ls-rating>
  </div> -->
</div>
<!-- <div class="hoverDiv">
  <div class="pnlFilterItrOuter">
    <ul class="filterItr">
      <div class="filterItrLi" *ngFor="let variantType of variantTypes; let variantTypeIdx = index;">
        <div class="pnlMandtryFieldInner">
          <p class="variantDesc">{{variantType.variantHeading}}</p>
          <ul class="variantItr">
            <li class="variantItrLi" [class.filterSelected]="(optionIdx == selectedFilterIdx)||(optionIdx == 0 && defaultClass == true)"
              *ngFor="let variant of variantType.options; let optionIdx = index;" (click)="setProductVariant($event, variant.mappedUniqueIds, optionIdx)">
              <p class="filter">{{variant.option}}</p>
            </li>
          </ul>
        </div>
      </div>
    </ul>
  </div>
</div> -->
</div>