<header *ngIf="!checkOutVisibility">
  <!-- <mat-sidenav-container class="">
        <mat-sidenav ngClass="menu-width" ngClass.xs="menu-width-xs" #accountDashboard opened="false" mode="over">
            <app-category></app-category>
        </mat-sidenav> -->
  <div fxLayout="column" class="header-column desktop-layout">
    <div fxLayout="row" class="header-top defaultWidth" fxShow.gt-xs fxHide.lt-sm>
      <div fxLayout="row" fxFlex="17" fxFlexAlign="start" style="position:relative; height:100%">
        <a [routerLink]="''" style="height:100%; width:100%; position:relative;" *ngIf="companyDetails">
          <img class="logo" [src]="companyDetails?.storeLogo ?companyDetails?.storeLogo:defaultLogo " style="padding-left: 20px;"
            (error)="uploadDefaultLogoImage(this)" title="{{companyDetails?.storeName}}" alt="{{companyDetails?.storeName}}">
        </a>
      </div>
      <div fxLayout="column" fxFlex="83" fxFlexAlign="end" style="height:100%" fxLayoutAlign="end end" class="heading-tag">
        <div class="upperPnl">
          <div class="rightPnl">

            <div class="socialPnlBar">
              <ul class="socialShare" *ngIf="socialSettings != undefined">
                <li class="floatedLeft" *ngIf="socialSettings.fbPageURL!= ''">
                  <div id="facebook" class="imagePnl">
                    <a href={{socialSettings.fbPageURL}} class="facebook" title="Facebook"></a>
                  </div>
                </li>
                <li class="floatedLeft" *ngIf="socialSettings.instagramPageURL!= ''">
                  <div id="instagram" class="imagePnl">
                    <a href={{socialSettings.instagramPageURL}} class="instagram" title="Instagram"></a>
                  </div>
                </li>
                <li class="floatedLeft" *ngIf="socialSettings.pinterestPageURL!= ''">
                  <div id="pinterest" class="imagePnl">
                    <a href={{socialSettings.pinterestPageURL}} class="pinterest" title="Pinterest"></a>
                  </div>
                </li>
                <li class="floatedLeft" *ngIf="socialSettings.googlePageURL!= ''">
                  <div id="gplus" class="imagePnl">
                    <a href={{socialSettings.googlePageURL}} class="gPlus" title="gPlus"></a>
                  </div>
                </li>
                <li class="floatedLeft" *ngIf="socialSettings.linkedInPageURL!= ''">
                  <div id="linkedin" class="imagePnl">
                    <a href={{socialSettings.linkedInPageURL}} class="linkedin" title="LinkedIn"></a>
                  </div>
                </li>
                <li class="floatedLeft" *ngIf="socialSettings.twitterPageURL!= ''">
                  <div id="twitter" class="imagePnl">
                    <a href={{socialSettings.twitterPageURL}} class="twitter" title="Twitter"></a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="leftPnl">
            <div class="searchPnlBar">
              <div class="searchIcon">
                <i class="fa fa-search"></i>
              </div>
              <app-search></app-search>
            </div>
          </div>
        </div>
        <div class="lowerPnl">
          <div fxLayout="row" class="menuLinksMainPanel" fxShow.gt-xs fxHide.lt-sm>
            <ul class="menuLinksItr">
              <li class="floatedLeft">
                <a class="mainLink" routerLink="" routerLinkActive="active">Home</a>
              </li>
              <li class="floatedLeft">
                <a id="categories" class="mainLink" routerLinkActive="active">Categories
                  <span id="downArrowSpan" class="fa fa-caret-down">
                  </span>
                </a>
                <div class="fetch-categories">
                  <ul *ngIf="categoryList != undefined">
                    <li *ngFor="let category of categoryList">
                      <div id="itrCat-0">
                        <a attr.categoryid={{category?.categoryId}} class="lnkCatName" id="lnkCatName" routerLink="collections/{{category?.categoryURL}}">
                          {{category?.categoryName}}
                          <!-- comment below line
                          <span *ngIf="category.categoryModels != undefined" class="mob-arrow-down fa fa-lg fa-angle-down"></span>  -->
                        </a>
                        <ul *ngIf="category?.categoryModels != undefined" class="itrCat">
                          <li *ngFor="let subCat of category?.categoryModels">
                            <div id="itrCat-1">
                              <a attr.categoryid={{subCat?.categoryId}} class="lnkCatName" id="lnkCatName" routerLink="collections/{{subCat?.categoryURL}}"
                                style="position:relative">
                                {{subCat?.categoryName}}
                                <span *ngIf="subCat?.categoryModels != undefined" class="arrow-upleft fa fa-lg fa-angle-right"
                                  style="position: absolute;right: 9px;font-weight: bold;"></span>
                              </a>
                              <ul *ngIf="subCat?.categoryModels != undefined" class="itrCat secCat">
                                <li *ngFor="let secLevel of subCat?.categoryModels" class="secLevel">
                                  <div id="itrCat-2">
                                    <a attr.categoryid={{secLevel?.categoryId}} class="lnkCatName" id="lnkCatName"
                                      routerLink="collections/{{secLevel?.categoryURL}}">
                                      {{secLevel?.categoryName}}
                                    </a>
                                    <ul *ngIf="secLevel?.categoryModels != undefined" class="itrCat thirdCat">
                                      <li *ngFor="let thirdLevel of secLevel?.categoryModels" class="thirdLevel">
                                        <div id="itrCat-3">
                                          <a attr.categoryid={{thirdLevel?.categoryId}} class="lnkCatName" id="lnkCatName"
                                            routerLink="collections/{{thirdLevel?.categoryURL}}">
                                            {{thirdLevel?.categoryName}}
                                          </a>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="floatedLeft best-seller" *ngFor="let collection of collectionArray; let par_index = index;">
                <!-- <a routerLink="partners" routerLinkActive="active">Categories</a> -->
                <a class="mainLink" routerLinkActive="active">{{collection?.collectionName}}
                  <span id="downArrowSpan" class="fa fa-caret-down"></span>
                </a>
                <div class="gridShow">
                  <div class="pnlProduct">
                    <div id="viewAllPnl"></div>
                    <ul class="gridItr">
                      <li class="floatedLeft" *ngFor="let productData of collection?.productModels;">
                        <div class="productTile">
                          <div class="productImagePnl">
                            <a id="lnkProduct" [href]="goToProductDetailPage(productData)">
                              <img class="center-position" id="productImage" [src]="(productData?.imageUrl && productData?.images && productData?.images[0])?productData.imageUrl+'m/'+productData?.images[0]:defaultPath "
                                alt="" (error)="imgErrorHandler($event)">
                            </a>
                          </div>
                          <div class="productContentPnl">
                            <p class="productTitle">{{productData.productName}}</p>
                            <div class="pricePnl">
                              <p class="mrp" *ngIf="productData?.mrp > productData?.salePrice && productData?.showSaving == 1">
                                {{productData?.mrp | currency:currType:'symbol': priceRoundOffInteger}}
                              </p>
                              <p class="discount" *ngIf="productData?.discountPercent && productData?.discountPercent!=0">{{productData?.discountPercent}}%
                                &nbsp;off</p>
                              <!-- <p id="productData?.mrp">{{productData?.salePrice | currency:currType:'symbol': priceRoundOffInteger}}</p> -->
                              <p class="salePrice">{{productData?.salePrice | currency:currType:'symbol':
                                priceRoundOffInteger}}</p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li class="floatedLeft">
                <a class="mainLink" routerLink={{aboutUsLink}}>About Us</a>
              </li>
              <li>
                <a class="mainLink" *ngIf="contactUsPresence" routerLink={{contactLnk}}>Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="header-bottom">
      <div class="defaultWidth">
        <div class="user-links">
          <div class="cart" [ngClass]="{'hide': !allowCart}">
            <a routerLink="shopping-cart" routerLinkActive="active">
              <span class="fa fa-lg fa-shopping-cart"></span>
              <span class="cart-count">({{cartCount}})</span>
            </a>
          </div>
          <!-- To Be Hidden when user not logged in  -->
          <div *ngIf="loggedIn" id="pnlLoginDtls" class="displayLoginDtls">
            <!-- <p id="lblHi" class="lblUserName">
              Welcome
            </p> -->
            <p id="lblUserName" class="lblUserName" [innerHTML]="userState.userName !=null ? userState.userName : userState.fName + '!'"></p>
            <div *ngIf="loggedIn" id="pnlMyAccount" class="pnlMyAccount">
              <span id="lblMyAccnt" class="lblMyAccnt"></span>
              <span class="caret"></span>
              <div id="dashOptionPnl" tabindex="1">
                <a routerLink="my-dashboard/profile" routerLinkActive="active">
                  <span class="fa fa-user" style="padding-right: 10px;"></span>Profile
                </a>
                <!-- <a *ngIf="!guestLoggedIn" routerLink="my-dashboard/orders" routerLinkActive="active">My Orders</a>
                  <a *ngIf="!guestLoggedIn" routerLink="my-dashboard/transactions" routerLinkActive="active">Transaction Log</a>
                  <a *ngIf="!guestLoggedIn" routerLink="my-dashboard/change-password" routerLinkActive="active">Change Password</a> -->
                <a *ngIf="allowWishlist" routerLink="my-dashboard/wishlist" routerLinkActive="active">
                  <span class="fa fa-heart-o" style="padding-right: 10px;"></span>Wishlist
                </a>
                <a id="lnk_login" class="signInDtl" (click)="logOut()">
                  <span class="fa fa-lock" style="padding-right: 10px;"></span>
                  Sign Out
                </a>
              </div>
            </div>
          </div>
          <p *ngIf="!loggedIn" class="login" (click)="onOpen()">Sign In</p>
          <!-- <p *ngIf="!loggedIn && !guestLoggedIn" class="track-order" (click)="showTrackPopOver()">Track Order</p> -->
          <!-- <p class="login" (click)="openDialog()">Sign In</p> -->
          <!-- <p class="track-order">Track Order</p> -->
        </div>
      </div>
    </div>
  </div>
  <div fxShow.lt-sm fxHide.gt-xs fxLayout="column" style="background-color:#f5f5f5;" class="mobile-layout">
    <div class="tagline" fxLayout="column" fxFlex="100">
      <p fxLayout="column" fxFlex="100" class="tagline">{{companyDetails?.storeTagLine}}</p>
    </div>
    <div fxLayout="row" style="border-top:5px solid #04582d; height:70px;">
      <div fxFlex="14" class="nav-panel" (click)="showCategories()"><span class="nav-bar"> </span></div>
      <div fxFlex="28" style="height:100%" class="img-logo" style="position:relative" (click)="navigateToHome()">
        <img src="{{companyDetails?.storeLogo}}" onerror="uploadDefaultLogoImage($event)" title="{{companyDetails?.storeName}}"
          alt="{{companyDetails.storeName}}" style="max-height:100%; max-width:100%;">
      </div>

      <div class="user-info" fxFlex="57" fxLayout="row" fxLayoutAlign="end end">
        <!-- <div *ngIf="currencyList != undefined" fxLayout="column" fxFlex="40" class="currency-combo">
          <div class="selected-div" (click)="showCurrencyList(currencyOption)">
            <span [id]="currencyList[selectedCurrencyIndex].currencyCode" class="currency-span"></span>
            <a class="currency-value"> {{currencyList[selectedCurrencyIndex].currencyCode}}</a>
          </div>
          <ul class="currency-option" #currencyOption>
            <li *ngFor="let model of currencyList; let idx = index" class="currency-li" (click)="updateCurrency( idx,currencyOption )">
              <span [id]="model.currencyCode" class="currency-span"></span>
              <a class="currency-value">{{model.currencyCode}}</a>
            </li>
          </ul>
        </div>
        <div class="login-panel" fxFlex="20"><span class="login-mob" (click)="openLoginDialog()"></span></div> -->
        <!-- <div class="track-panel" fxFlex="20" (click)="showTrackPopOver()"><span class="track-mob"></span></div> -->
        <div class="cart-panel" fxFlex="20" (click)="openCart()"><span class="cart-mob"></span><span class="cart-count">{{cartCount}}</span></div>
        <!-- <p fxLayout="column" fxFlex="100" class="tagline">{{companyDetails.storeTagLine}}</p> -->
      </div>
    </div>
  </div>
  <!-- </mat-sidenav-container> -->
</header>