import { Component, OnInit, Inject, PLATFORM_ID, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { LsMdDialog, LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { LoginService } from '../../../services/login.service';
import { UserModel } from '../../../common/models/user-model';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { CustomerSurveyService } from '../../../services/customerSurvey.service'
import { CustomerSurveyComponent } from '../../commonComponent/customer-survey/customer-survey.component'
import { AnswerModel } from '../../../common/models/answerModel'
import { CartCountService } from '../../../services/cart-count.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { CartModel } from '../../../common/models/cartModel';
import { CartProductModel } from '../../../common/models/cartProductModel';
import { CartService } from '../../../services/cart.service';
import { YesNoPopupComponent } from '../../commonComponent/yes-no-popup/yes-no-popup.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  dataList;//data
  dialog: LsMdDialog<LoginComponent>;
  loginForm: FormGroup
  registerForm: FormGroup
  loginMobileForm: FormGroup
  mobileRegisterForm: FormGroup
  user: UserModel
  fbOauth: boolean
  googleOauth: boolean
  email: string = ""
  password: string = ""
  rememberMe: boolean = false;
  formId: number
  loggedInUser: boolean = false;

  constructor(
    public loginService: LoginService,
    public formBuilder: FormBuilder,
    private socialAuthService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    public customerSurveyService: CustomerSurveyService,
    public customerSurveyDialogService: LsDialogService<CustomerSurveyComponent>,
    private cartCountService: CartCountService,
    public okayMessageService: LsDialogService<OkayMessageComponent>,
    private cartService: CartService,
    private YesNoDialogService: LsDialogService<YesNoPopupComponent>
  ) { }

  ngOnInit() {
    if (Constants.VENDOR_DETAILS != undefined && Constants.VENDOR_DETAILS.onlineStoreSettingDTO != undefined && Constants.VENDOR_DETAILS.onlineStoreSettingDTO.socialMediaSettingsDTO != undefined) {
      if (Constants.VENDOR_DETAILS.onlineStoreSettingDTO.socialMediaSettingsDTO.fbAppId && Constants.VENDOR_DETAILS.onlineStoreSettingDTO.socialMediaSettingsDTO.fbAppId != "") {
        this.fbOauth = true;
      }
      if (Constants.VENDOR_DETAILS.onlineStoreSettingDTO.socialMediaSettingsDTO.googleJSONFile && Constants.VENDOR_DETAILS.onlineStoreSettingDTO.socialMediaSettingsDTO.googleJSONFile != "") {
        this.googleOauth = true;
      }
    }

    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem(Constants.LOGIN_COOKIE + Constants.SITE_ID + 'email') != undefined && localStorage.getItem(Constants.LOGIN_COOKIE + Constants.SITE_ID + 'email') != "") {
        let emailCookie = localStorage.getItem(Constants.LOGIN_COOKIE + Constants.SITE_ID + 'email');
        let pwdCookie = localStorage.getItem(Constants.LOGIN_COOKIE + Constants.SITE_ID + 'pwd');
        this.email = emailCookie;
        this.password = pwdCookie;
        this.rememberMe = true;
      }
      if (localStorage.getItem('currentUser') != null) {
        if (this.activatedRoute.snapshot.queryParamMap.get('moveTo') != undefined && this.activatedRoute.snapshot.queryParamMap.get('moveTo') == 'survey') {
          this.loggedInUser = true;
          this.navigateToHome()
          // this.openSurvey()
        }
      }
    }

    this.loginForm = this.formBuilder.group({
      email: [this.email, [Validators.email, Validators.required]],
      password: [this.password, [Validators.required]],
      remember: [this.rememberMe]
    })

    this.registerForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      emailSignUp: ["", [Validators.email, Validators.required]],
      passwordSignUp: ["", [Validators.required]]
    })

    this.loginMobileForm = this.formBuilder.group({
      email: [this.email, [Validators.email, Validators.required]],
      password: [this.password, [Validators.required]],
      remember: [this.rememberMe]
    })

    this.mobileRegisterForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      emailSignUp: ["", [Validators.email, Validators.required]],
      passwordSignUp: ["", [Validators.required]]
    })
  }

  changeTab($event, loginTab, signUpForm, signUp, errorLabel) {
    if ($event.target.className === "signup-tab") {
      signUpForm.className = "signup-form";
      loginTab.className = "login-form hide";
      signUp.className = "signup-tab active";
      // login.className = "login-tab";
      errorLabel.innerHTML = "";
    } else {
      signUpForm.className = "signup-form hide";
      loginTab.className = "login-form";
      signUp.className = "signup-tab";
      // login.className = "login-tab active";
      errorLabel.innerHTML = "";
    }
  }

  data;//data send back by dialog to parent component(appcomponent)
  onClick() {
    this.dialog.close(null);//fns to close dialog
  }

   loginSubmit($event, errorLabel) {       if(!isPlatformBrowser(this.platformId)) {         return       }
    let email, password;
    let rememberMe = this.loginForm.value.remember;
    if (this.loginForm.value.email != "") {
      email = this.loginForm.value.email;
      password = this.loginForm.value.password;
    } else {
      email = this.loginMobileForm.value.email;
      password = this.loginMobileForm.value.password;
    }
    if (this.loginForm.status === "INVALID" && this.loginMobileForm.status === "INVALID") {
      errorLabel.className = "errorLabel show";
      errorLabel.innerHTML = "Field is Required";
    } else {
      let userType = Constants.UserType.registered;
      this.loginService.login(this.rememberMe, email, password, false, userType, (resp) => {
        if (!resp.error && resp.data) {
          if (resp.otherInfo && resp.otherInfo.cartWishlist && resp.otherInfo.cartWishlist.data) {
            let data: Array<CartModel> = resp.otherInfo.cartWishlist.data;
            let wishlistCart: CartModel, userCart: CartModel;
            if (data[0] && data[0].cartWishListType == Constants.CART_WISHLIST_TYPE.CART) {
              userCart = data[0];
            } else if (data[0] && data[0].cartWishListType == Constants.CART_WISHLIST_TYPE.WISHLIST) {
              wishlistCart = data[0];
            }
            if (data[1] && data[1].cartWishListType == Constants.CART_WISHLIST_TYPE.CART) {
              userCart = data[1];
            } else if (data[1] && data[1].cartWishListType == Constants.CART_WISHLIST_TYPE.WISHLIST) {
              wishlistCart = data[1];
            }
            if (wishlistCart) {
              this.setLocalCart(wishlistCart, true);
            }
            if (userCart) {
              this.cartAppendHandler(userCart);
            }
          }
        } else {
          errorLabel.className = "errorLabel show";
          errorLabel.innerHTML = resp.msgList[0];
        }
        if (rememberMe && resp.data != undefined) {
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(Constants.LOGIN_COOKIE + Constants.SITE_ID + 'email', email);
            localStorage.setItem(Constants.LOGIN_COOKIE + Constants.SITE_ID + 'pwd', resp.data.data.password);
          }
        }
      })
    }
  }

  signUpSubmit($event, errorLabel) {
    let storeUserModel = new UserModel()
    let mode
    if (this.registerForm.value.emailSignUp != "") {
      storeUserModel.email = this.registerForm.value.emailSignUp
      storeUserModel.password = this.registerForm.value.passwordSignUp
      storeUserModel.userName = this.registerForm.value.name
    } else {
      storeUserModel.email = this.mobileRegisterForm.value.emailSignUp
      storeUserModel.password = this.mobileRegisterForm.value.passwordSignUp
      storeUserModel.userName = this.mobileRegisterForm.value.name
    }
    if (this.registerForm.status === "INVALID" && this.mobileRegisterForm.status === "INVALID") {
      errorLabel.className = "errorLabel show";
      errorLabel.innerHTML = "Field is Required";
    } else {
      storeUserModel.userType = Constants.UserType.registered
      mode = Constants.LOGIN_MODE.DEFAULT
      this.loginService.sign_up(storeUserModel, null, mode, (resp) => {
        if (this.activatedRoute.snapshot.queryParamMap.get('moveTo') != undefined) {
          if (this.activatedRoute.snapshot.queryParamMap.get('moveTo') == 'profile') {
            let navigateUrl = 'my-dashboard/' + this.activatedRoute.snapshot.queryParamMap.get('moveTo').toString();
            this.router.navigate([navigateUrl]);
          } else if (this.activatedRoute.snapshot.queryParamMap.get('moveTo') == 'survey') {
            let navigateUrl = ''
            this.router.navigate([navigateUrl])
            this.openSurvey()
          }
        } else if (location.href.includes('login')) {
          if (this.activatedRoute.snapshot.queryParamMap.get('moveTo') != undefined &&
            this.activatedRoute.snapshot.queryParamMap.get('moveTo') == 'survey') {
            this.openSurvey()
          } else {
            location.href = "my-dashboard/profile";
          }
        } else {
          if (resp.data !== null) {
            this.dialog.close(null);
          } else {
            errorLabel.className = "errorLabel show";
            errorLabel.innerHTML = resp.msgList[0];
          }
        }
      })
    }
  }

  public socialSignIn(socialPlatform: string, errorLabel) {
    let socialPlatformProvider;
    if (socialPlatform === "facebook") {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform === "google") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {
        let user: UserModel = new UserModel();
        user.userName = userData.name;
        user.email = userData.email;
        user.userType = Constants.UserType.registered;
        this.loginService.sign_up(user, null, Constants.LOGIN_MODE.OAUTH, resp => {
          if (!resp.error && resp.data) {
            // this.dialog.close(null);
            if (resp.otherInfo && resp.otherInfo.cartWishlist && resp.otherInfo.cartWishlist.data) {
              let data: Array<CartModel> = resp.otherInfo.cartWishlist.data;
              let wishlistCart: CartModel, userCart: CartModel;
              if (data[0] && data[0].cartWishListType == Constants.CART_WISHLIST_TYPE.CART) {
                userCart = data[0];
              } else if (data[0] && data[0].cartWishListType == Constants.CART_WISHLIST_TYPE.WISHLIST) {
                wishlistCart = data[0];
              }
              if (data[1] && data[1].cartWishListType == Constants.CART_WISHLIST_TYPE.CART) {
                userCart = data[1];
              } else if (data[1] && data[1].cartWishListType == Constants.CART_WISHLIST_TYPE.WISHLIST) {
                wishlistCart = data[1];
              }
              if (wishlistCart) {
                this.setLocalCart(wishlistCart, true);
              }
              if (userCart) {
                this.cartAppendHandler(userCart);
              }
            }
          } else {
            errorLabel.className = "errorLabel show";
            errorLabel.innerHTML = resp.msgList[0];
          }
        })
      }
    )
  }

  goToForgotPw() {
    this.router.navigate(['forgot-password']);
    if (location.href.includes('login')) {
    } else {
      this.dialog.close(null);
    }
  }

 cartAppendHandler(prevUserCart: CartModel) {       if(!isPlatformBrowser(this.platformId)) {         return       }
    let localCart = new CartModel();
    if (localStorage.getItem('localCart')) {
      localCart = JSON.parse(localStorage.getItem('localCart'));
    }
    if (localCart && localCart.cartProductModels && localCart.cartProductModels.length > 0) {
      this.YesNoDialogService.open(YesNoPopupComponent, {}, "Do you want to append to previous cart?").subscribe(response => {
        if (response == "yes") {
          let updatedLocalCart: CartModel = new CartModel();
          updatedLocalCart.cartWishListType = Constants.CART_WISHLIST_TYPE.CART;
          updatedLocalCart.cartProductModels = localCart.cartProductModels;
          if (prevUserCart && prevUserCart.cartProductModels && prevUserCart.cartProductModels.length > 0) {
            prevUserCart.cartProductModels.forEach((ele) => {
              let isPresent: boolean;
              for (var index = 0; index < localCart.cartProductModels.length; index++) {
                var localPro = localCart.cartProductModels[index];
                if (localPro.titleId == ele.titleId) {
                  isPresent = true;
                  if (localPro.variantId && ele.variantId) {
                    isPresent = false;
                    if (localPro.variantId == ele.variantId) {
                      isPresent = true;
                      break;
                    }
                  }
                }
              }
              if (!isPresent) {
                updatedLocalCart.cartProductModels.push(ele);
              }
            });
          }
          this.cartService.computeCart(updatedLocalCart, (resp) => {
            if (!resp.error && resp.data && resp.data[0]) {
              this.setLocalCart(resp.data[0], false);
            } else {
              this.okayMessageService.open(OkayMessageComponent, {}, 'Error appending to previous cart!');
            }
          })
        } else if (response == "no") {
          this.setLocalCart(prevUserCart, false);
        }
        this.afterLogin();
      });
    } else {
      this.setLocalCart(prevUserCart, false);
      this.afterLogin();
    }
  }

  setLocalCart(cart: CartModel, isWishlist: boolean) {     if(!isPlatformBrowser(this.platformId)) {       return     }
    let newLocalCart: CartModel = new CartModel();
    if (cart && cart.cartProductModels && cart.cartProductModels.length > 0) {
      newLocalCart.cartProductModels = [];
      cart.cartProductModels.forEach((ele) => {
        if (!ele.offer) {
          let localCartEle: CartProductModel = new CartProductModel();
          if (ele.titleId)
            localCartEle.titleId = ele.titleId;
          if (ele.variantId)
            localCartEle.variantId = ele.variantId;
          if (ele.numCopies)
            localCartEle.numCopies = ele.numCopies;
          newLocalCart.cartProductModels.push(localCartEle);
        }
      });
    }
    if (!isWishlist) {
      localStorage.setItem('localCart', JSON.stringify(newLocalCart));
      this.cartCountService.updateCart(newLocalCart);
    } else {
      localStorage.setItem('wishlist', JSON.stringify(newLocalCart));
      this.cartCountService.updateWishlistCart(newLocalCart);
    }
  }

  afterLogin() {
    if (this.activatedRoute.snapshot.queryParamMap.get('moveTo') != undefined) {
      if (this.activatedRoute.snapshot.queryParamMap.get('moveTo') == 'profile') {
        let navigateUrl = 'my-dashboard/' + this.activatedRoute.snapshot.queryParamMap.get('moveTo').toString();
        this.router.navigate([navigateUrl]);
      } else if (this.activatedRoute.snapshot.queryParamMap.get('moveTo') == 'survey') {
        this.navigateToHome();
        // this.openSurvey()
      }
    } else if (location.href.includes('login')) {
      if (this.activatedRoute.snapshot.queryParamMap.get('moveTo') != undefined &&
        this.activatedRoute.snapshot.queryParamMap.get('moveTo') == 'survey') {
        // this.openSurvey()
      } else {
        location.href = "my-dashboard/profile";
      }
    } else {
      this.dialog.close(null);
    }
  }

  navigateToHome() {
    let url = ''
    this.router.navigate([''])
  }

  openSurvey() {
    let data: Array<{ formId: number }> = []
    this.customerSurveyService.getSurveyForm(resp => {
      if (!resp.error) {
        if (resp.data != undefined && resp.data != null && resp.data.formId != undefined && resp.data.formId != null)
          this.formId = resp.data.formId
        data.push({
          formId: this.formId
        })
        this.customerSurveyDialogService.open(CustomerSurveyComponent, {}, data).subscribe((response) => {
          if (response == 'yes') {
            let answerModel = new AnswerModel()
            answerModel.formId = this.formId
            let isSurveyFormFilled = false
            this.cartCountService.surveyFormFillObservable.subscribe((flag) => {
              isSurveyFormFilled = flag
            })
            if (!isSurveyFormFilled) {
              this.customerSurveyService.submitQuestionsForSurvey(answerModel, (resp) => {
                if (!resp.error)
                  this.navigateToHome()
              })
            } else {
              this.navigateToHome()
            }
          }
        })
      } else {
        this.navigateToHome()
        this.okayMessageService.open(OkayMessageComponent, null, 'Survey has been already completed!').subscribe(response => {
        })
      }
    })
  }

}
