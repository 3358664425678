import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContactQueryModel } from '../../../common/models/contact-us-query-model';
import { ContactService } from '../../../services/contact.service';
import { LsDialogService, LsMdDialog } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import * as FormData from 'form-data';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

  dialog: LsMdDialog<PartnersComponent>;
  formData = new FormData();
  partnersForm: FormGroup;
  selectedFile: File;

  constructor(
    public formBuilder: FormBuilder,
    private contactService: ContactService,
    public dialogService: LsDialogService<OkayMessageComponent>
  ) { }

  ngOnInit() {
    this.partnersForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      message: ["", [Validators.required]],
      file: ["", [Validators.required]]
    })
  }

  closeDialog() {
    this.dialog.close(null);//fns to close dialog
  }

  onFileChange(event) {
    this.selectedFile = event.target.files[0];
    if (event.target.files.length > 0) {
      let fileToUpload = event.target.files[0];
      this.formData = new FormData();
      this.formData.append("file[]", fileToUpload, fileToUpload.name);
    }
  }

  submitPartners(errorTag) {
    let queryModel: ContactQueryModel = new ContactQueryModel();
    queryModel.email = this.partnersForm.value.email;
    queryModel.desc = this.partnersForm.value.message;
    this.formData.append("file", this.selectedFile);
    this.formData.append("data", JSON.stringify(queryModel));
    if (this.selectedFile != undefined && this.selectedFile.size > 5242880) {
      this.dialogService.open(OkayMessageComponent, {}, "File size should be less than 5 MB!!").subscribe({
      });
    } else if (queryModel.email === "" || queryModel.email === null) {
      errorTag.innerHTML = "Please enter your email ID!";
    } else {
      this.contactService.sendGetForMeRequest(this.formData, queryModel.email, this.partnersForm.value.name, resp => {
        this.partnersForm.reset();
        this.dialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(response => {
          this.closeDialog();
        })
      })
    }
  }

}
