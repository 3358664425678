import { Component, OnInit } from '@angular/core';
import { LsMdDialog, LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { HomePageService } from '../../../services/home-page.service';

@Component({
  selector: 'app-okay-message',
  templateUrl: './okay-message.component.html',
  styleUrls: ['./okay-message.component.scss']
})
export class OkayMessageComponent implements OnInit {

  msg;
  dialog: LsMdDialog<OkayMessageComponent>
  dataList;
  showNewsLetter:boolean=false;

  constructor(
    private homePageService:HomePageService,
    public dialogService: LsDialogService<OkayMessageComponent>
  ) { }

  ngOnInit() {
   this.msg = this.dataList;
   if(this.msg == 'newsletter'){
    this.showNewsLetter = true;
   }
  }

  closeDialog(){
    this.dialog.close(null);
  }
  subscribeNewsletter(error, input) {
    let email = input.value;
    if (email == "" || !this.validateEmail(email)) {
      error.className = "show errorLbl"
      error.innerHTML = "Invalid Email!!";
      input.className = "tfSubscriptionEmail error-tag";
    } else {
      error.className = "hide errorLbl";
      error.innerHTML = "";
      input.className = "tfSubscriptionEmail";
      this.homePageService.subscribeNewsletter(input.value, (resp) => {
        this.dialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(response => {
          input.value = "";
          this.closeDialog();
        })
      })
    }
  }

  subscribeKeyup(event, error, input){
    if(event.target.value == ""){
      error.className = "visibility-hide errorLbl";
      error.innerHTML = "&nbsp;";
      input.className = "tfSubscriptionEmail";
    }
  }


  validateEmail(email) {
    var at = email.indexOf("@");
    var dot = email.lastIndexOf(".");
    return !(at < 1 || at + 2 > dot || dot + 2 > email.length);
  }

}
