<div *ngIf="!errorFlag" class="static-page">
  <div class="pnlHeadingStatic">
      <p class="heading">{{heading}}</p>
  </div>
  <div [innerHtml]='content | safeHtml' class="static-content" *ngIf="content != undefined"></div>
</div>

<div *ngIf="errorFlag && quickLinks != undefined" class="page-not-found">
  <img [src]="img404Src != undefined?img404Src:defaultPath" class="page-not-found-img" (error)="imgErrorHandler($event)">
</div>
