<div class="productReviewContainer">
  <div class="innerContainer">
    <div fxLayout="row" class="header">
      <p class="heading">{{lblAddReview}}</p>
      <span class="closeButton fa fa-times-circle-o" (click)="closeDialog()"></span>
    </div>
    <div class="addReviewForm">
      <form role="form" [formGroup]="addReviewForm" (ngSubmit)="addReviewSubmit($event)">
        <div class="pnlReviewBdy">
          <div class="pnlReviewNote">
            <p>{{note}}</p>
          </div>
          <div fxLayout="row" class="pnlTitleReview">
            <p>{{lblTitle}}</p>
            <input formControlName="title" type="text" tabindex="1" class="txtTitle">
          </div>
          <div fxLayout="row" class="pnlReviewContent">
            <p>{{lblReview}}</p>
            <textarea formControlName="review" type="text" tabindex="2" class="txtReview"></textarea>
          </div>
          <div fxLayout="row" class="pnlRating">
            <p>{{lblRating}}</p>
            <ls-rating formControlName="rating" tabindex="3" [editable]=true></ls-rating>
            <div class="updateButton">
                <!-- <button class="button lnkSignupBtn" tabindex="4" [disabled]="!mobileRegisterForm.valid"> Signup</button> -->
                <button class="reviewBtn" tabindex="4" [disabled]="!addReviewForm.valid">{{lblButton}}</button>
              </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>