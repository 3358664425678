<div class="catHeadingWrapper">
  <p class="catHeading">Our Offer</p>
</div>
<div class="offerWrapper">
  <div class="pnlOffer">
    <ul class="offerImgItr">
      <li class="floatedLeft" *ngFor="let offer of offers; let idx  = index" [title]="offer?.altText">
        <a class="lnkOfferImg lnkOfferImgShow" href="" (click)="navigate(offer.imageHref)">
          <img class="imgOffer" [src]="isMobile?offer.mobilUrl:offer.imageName" (error)="offerImgErrorHandler($event)">
        </a>
      </li>
    </ul>
  </div>
</div>