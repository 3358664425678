import { Component, OnInit, Input } from '@angular/core'
import { BrandModel } from '../../../common/models/brandModel';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {
  
  @Input('brands') brands: Array<BrandModel> = [];

  constructor(    
    ) { }

  ngOnInit() {
  }

}
